<template>
  <div>
    <header>
      <h3 class="has-text-weight-bold has-text-dark mb-3">{{ $t('wq.wqs') }}</h3>
      <b-field>
        <b-switch v-model="wqs"/>
      </b-field>
    </header>

    <div class="is-divider my-5"></div>

    <section v-show="wqs">
      <div class="is-flex is-align-items-center is-justify-content-space-between pb-3">
        <div>
          <h3 class="has-text-weight-bold has-text-dark">Water Quality Range</h3>
          <p class="has-text-secondary my-3">Set {{ $t('wq.wqs') }}</p>
        </div>
        <div class="has-text-right">
          <b-button outlined type="is-primary" class="mb-2" @click="resetParameter" v-if="canEditWqs">Reset</b-button>
          <b-button type="is-primary is-gradient" class="ml-2" @click="wqModal = true" v-if="canAddWqs">Set Parameter</b-button>
        </div>
      </div>

      <div>
        <b-collapse
            class=""
            v-for="(category, key) in categories"
            :key="key"
            :open="isOpen === category"
            @open="isOpen = category">
          <div class="card mb-2"
               slot="trigger"
               slot-scope="props"
               role="button">
            <div class="card-header border-bottom-0">
              <p class="card-header-title">{{ $t('wq.' + category) }}</p>
              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
            </div>
          </div>
          <div v-if="getSelectedParameterByCategory(category).length">
            <div class="content">
              <div v-for="(parameter, key) in getSelectedParameterByCategory(category)" :key="key">
                <div class="card mb-4" v-if="parameter.category === category">
                  <div class="card-content">
                    <div class="is-flex is-justify-content-space-between is-align-items-center">
                      <p class="mb-4 has-text-weight-bold">{{ getWqLabel(parameter.name) }}</p>

                      <div class="cursor-pointer"
                           @click="deleteParameter(parameter.name, parameter.category, true)"
                           v-if="canEditWqs">
                        <b-icon icon="close-box-outline"/>
                      </div>
                    </div>

                    <div class="columns is-align-items-center is-justify-content-space-between">
                      <b-field label="Min. Range" class="column is-3-desktop" v-if="parameter.range_edit.length">
                        <b-input type="number" step="any" v-model.number="parameter.min_value"
                                 @change.native="updateParameter(parameter)"
                                 @keyup.native="handleParameterInputValue(parameter)" :disabled="!canEditWqs"/>
                      </b-field>

                      <div class="px-4 is-flex-grow-1">
                        <b-slider v-model="parameter.range_edit"
                                  :min="parameter.min_limit"
                                  :max="parameter.max_limit"
                                  :step="parameter.step"
                                  @input="handleUpdateSlider(parameter)"
                                  @change="updateParameter(parameter)" :disabled="!canEditWqs"/>
                      </div>

                      <b-field label="Max. Range" class="column is-3-desktop">
                        <b-input type="number" step="any" v-model.number="parameter.max_value"
                                 @change.native="updateParameter(parameter)"
                                 @keyup.native="handleParameterInputValue(parameter)" :disabled="!canEditWqs"/>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="has-text-centered mt-3 mb-4">{{ $t('no') }} Data</p>
          </div>
        </b-collapse>
      </div>
    </section>

    <!--  Modal Water Quality Parameters -->

    <b-modal
        v-model="wqModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <set-parameters v-model="parameters" is-modal @close="props.close"></set-parameters>
      </template>
    </b-modal>

  </div>
</template>

<script>
import SetParameters from "@/components/Farm/Setting/SetParameters";
import waterQualityHelper from "@/lib/waterQualityHelper";

export default {
  name: "WaterQualitySetting",
  components: {
    SetParameters,
  },
  computed: {
    canAddWqs() {
      return !this.selectedFarm.farm_id || this.$store.getters["farm/canAddWqs"]
    },
    canEditWqs() {
      return this.$store.getters["farm/canEditWqs"] || !this.farm_id
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    wqs: {
      get() {
        return this.$store.state.setting.wqs
      },
      set() {
        this.$store.dispatch('setting/toggleWqs')
      }
    }
  },
  data: () => {
    return {
      categories: ['general', 'chemical', 'biological'],
      isOpen: 'general',

      wqModal: false,

      parameters: {
        all_general: {
          value: false
        },
        all_chemical: {
          value: false
        },
        all_biological: {
          value: false
        },

        //general
        ph: {
          category: 'general',
          value: false
        },
        ph_morning_to_morning: {
          category: 'general',
          value: false
        },
        ph_morning_to_evening: {
          category: 'general',
          value: false
        },
        ph_evening_to_evening: {
          category: 'general',
          value: false
        },
        do: {
          category: 'general',
          value: false
        },
        temperature: {
          category: 'general',
          value: false
        },
        turbidity: {
          category: 'general',
          value: false
        },
        salinity: {
          category: 'general',
          value: false
        },
        oxygen_saturation: {
          category: 'general',
          value: false
        },
        orp: {
          category: 'general',
          value: false
        },
        water_height: {
          category: 'general',
          value: false
        },
        // chemical
        tom: {
          category: 'chemical',
          value: false
        },
        alkalinity: {
          category: 'chemical',
          value: false
        },
        po4: {
          category: 'chemical',
          value: false
        },
        no3: {
          category: 'chemical',
          value: false
        },
        no2: {
          category: 'chemical',
          value: false
        },
        nh4: {
          category: 'chemical',
          value: false
        },
        nh3: {
          category: 'chemical',
          value: false
        },
        tan: {
          category: 'chemical',
          value: false
        },
        co3: {
          category: 'chemical',
          value: false
        },
        hco3: {
          category: 'chemical',
          value: false
        },
        ca: {
          category: 'chemical',
          value: false
        },
        mg: {
          category: 'chemical',
          value: false
        },
        hard: {
          category: 'chemical',
          value: false
        },
        // biological
        tvctbc: {
          category: 'biological',
          value: false
        },
        tbc: {
          category: 'biological',
          value: false
        },
        tvc: {
          category: 'biological',
          value: false
        },
        yvc: {
          category: 'biological',
          value: false
        },
        gvc: {
          category: 'biological',
          value: false
        },
        lbc: {
          category: 'biological',
          value: false
        },
        bvc: {
          category: 'biological',
          value: false
        },
        bga: {
          category: 'biological',
          value: false
        },
        ga: {
          category: 'biological',
          value: false
        },
        gga: {
          category: 'biological',
          value: false
        },
        dino: {
          category: 'biological',
          value: false
        },
        dia: {
          category: 'biological',
          value: false
        },
        pro: {
          category: 'biological',
          value: false
        },
        eug: {
          category: 'biological',
          value: false
        },
        plankton: {
          category: 'biological',
          value: false
        },
      },

      selected_parameters: [],
    }
  },
  watch: {
    parameters: {
      deep: true,
      handler() {
        this.handleParametersChange()
      }
    },
    selected_parameters: {
      deep: true,
      handler(value) {
        this.$emit('update-data', {
          canContinue: true,
          data: value
        })
      }
    }
  },
  created() {
    this.$loading(false)
    this.getData()
  },
  methods: {
    getSelectedParameterByCategory(category) {
      return this.selected_parameters.filter(e => e.category === category)
    },

    isParameterUpdate(a, b) {
      return a.min_limit !== b.min_limit || a.min_value !== b.min_value || a.max_limit !== b.max_limit || a.max_value !== b.max_value
    },

    async resetParameter() {
      this.$loading()

      for (let i = 0; i < this.selected_parameters.length; i++) {
        let parameter = this.selected_parameters[i]
        let defaultValue = this.defaultRangeValue(parameter.name)

        if (this.isParameterUpdate(parameter, defaultValue)) {
          parameter.min_limit = defaultValue.min_limit
          parameter.min_value = defaultValue.min_value
          parameter.max_limit = defaultValue.max_limit
          parameter.max_value = defaultValue.max_value

          this.handleParameterInputValue(parameter)
          await this.updateParameter(parameter)
        }
      }

      this.$loading(false)
    },

    async getData() {
      if (this.farm_id) {
        this.$loading()
        this.selected_parameters = []

        let res = await this.$store.dispatch('farm/getWqs', parseInt(this.farm_id))

        if (!res.error && res.data && res.data.length) {
          this.selected_parameters = res.data.map(e => {

            if (this.parameters[e.name]) this.parameters[e.name].value = true

            switch (e.name) {
              case 'ph_morning_to_morning':
              case 'ph_morning_to_evening':
              case 'ph_evening_to_evening':
                e.range_edit = e.max_value
                break
              default:
                e.range_edit = [e.min_value, e.max_value]
                break
            }

            let defaultValue = this.defaultRangeValue(e.name)
            e.step = defaultValue.step

            return e
          }).sort((a, b) => a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }))
        }
        this.$loading(false)
      } else {
        this.wqs = true
        this.addParameter('ph', 'general')
        this.addParameter('ph_morning_to_morning', 'general')
        this.addParameter('ph_morning_to_evening', 'general')
        this.addParameter('ph_evening_to_evening', 'general')
        this.addParameter('do', 'general')
        this.addParameter('temperature', 'general')
      }
    },

    async handleParametersChange() {
      this.$loading()
      for (let k in this.parameters) {
        let item = this.parameters[k]
        if (item.category) {
          if (item.value) {
            await this.addParameter(k, item.category)
          } else {
            await this.deleteParameter(k, item.category)
          }
        }
      }
      this.$loading(false)
      this.getData()
    },
    async addParameter(name, category) {
      let find = this.selected_parameters.find(e => e.name === name && e.category === category)
      if (!find) {
        let defaultValue = this.defaultRangeValue(name)

        let data = {
          farm_id: parseInt(this.farm_id),
          name: name,
          category: category,
          min_limit: defaultValue.min_limit,
          max_limit: defaultValue.max_limit,
          min_value: defaultValue.min_value,
          max_value: defaultValue.max_value,
          created_at: this.$timestamp()
        }

        if (this.farm_id) {
          await this.$store.dispatch('farm/addWqs', data)
        }

        switch (data.name) {
          case 'ph_morning_to_morning':
          case 'ph_morning_to_evening':
          case 'ph_evening_to_evening':
            data.range_edit = defaultValue.max_value
            break
          default:
            data.range_edit = [defaultValue.min_value, defaultValue.max_value]
            break
        }
        data.step = defaultValue.step

        this.selected_parameters.push(data)
      }
    },

    removeParameter(name, key) {
      this.parameters[name].value = false
      this.selected_parameters.splice(key, 1)
    },

    async deleteParameter(name, category, modal = false) {
      let key = Object.keys(this.selected_parameters).find(e => this.selected_parameters[e].name === name && this.selected_parameters[e].category === category)
      if (key !== undefined) {
        let find = this.selected_parameters.find(e => e.name === name && e.category === category)

        if (find && find.wqs_id) {
          let fn = async () => {
            await this.$store.dispatch('farm/deleteWqs', find.wqs_id)
            this.removeParameter(name, key)
          }
          if (modal) {
            this.$delete(async () => {
              this.$loading()
              await fn()
              this.$loading(false)
              this.getData()
            })
          } else await fn()
        } else {
          this.removeParameter(name, key)
        }
      }
    },
    getWqLabel(name) {
      return waterQualityHelper.getWqLabel(name)
    },
    defaultRangeValue(name) {
      return waterQualityHelper.getDefaultSetting(name)
    },
    handleUpdateSlider(parameter) {
      switch (parameter.name) {
        case 'ph_morning_to_morning':
        case 'ph_morning_to_evening':
        case 'ph_evening_to_evening':
          parameter.max_value = parameter.range_edit
          break
        default:
          parameter.min_value = parameter.range_edit[0]
          parameter.max_value = parameter.range_edit[1]
          break
      }
    },
    handleParameterInputValue(parameter) {
      if (parameter.max_value > parameter.max_limit) parameter.max_value = parameter.max_limit
      if (parameter.min_value < parameter.min_limit) parameter.min_value = parameter.min_limit
      parameter.range_edit = [parameter.min_value, parameter.max_value]
    },
    async updateParameter(parameter) {
      if (this.farm_id) {
        await this.$store.dispatch('farm/addWqs', {
          farm_id: parameter.farm_id,
          name: parameter.name,
          category: parameter.category,
          min_limit: parameter.min_limit,
          max_limit: parameter.max_limit,
          min_value: parameter.min_value,
          max_value: parameter.max_value,
          created_at: parameter.created_at,
        })
      }
    },
  }
}
</script>
