import i18n from "@/i18n";

let wq = {}

wq.getDefaultSetting = (item_name) => {
  let res = {
    min_value: 0,
    max_value: 0,
    min_limit: 0,
    max_limit: 0
  }

  switch (item_name) {
    case 'ph':
      res.min_limit = 0
      res.max_limit = 14
      res.min_value = 7.5
      res.max_value = 8.5
      res.step = 0.1
      break
    case 'ph_morning_to_morning':
    case 'ph_morning_to_evening':
    case 'ph_evening_to_evening':
      res.min_limit = 0
      res.max_limit = 10
      res.max_value = .5
      res.step = 0.1
      break
    case 'do':
      res.min_limit = 0
      res.max_limit = 14.6
      res.min_value = 3
      res.max_value = 14.6
      res.step = 0.1
      break
    case 'temperature':
      res.min_limit = 0
      res.max_limit = 40
      res.min_value = 28
      res.max_value = 31
      res.step = 1
      break
    case 'turbidity':
      res.min_limit = 0
      res.max_limit = 400
      res.min_value = 0
      res.max_value = 400
      res.step = 1
      break
    case 'water_height':
      res.min_limit = 0
      res.max_limit = 400
      res.min_value = 0
      res.max_value = 400
      res.step = 1
      break
    case 'salinity':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'oxygen_saturation':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'orp':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break


    case 'tom':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'alkalinity':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'po4':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'no3':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'no2':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'nh4':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'nh3':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'tan':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'co3':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'hco3':
      res.min_limit = 0
      res.max_limit = 1000
      res.min_value = 0
      res.max_value = 1000
      res.step = 1
      break
    case 'ca':
      res.min_limit = 0
      res.max_limit = 10000
      res.min_value = 0
      res.max_value = 10000
      res.step = 1
      break
    case 'mg':
      res.min_limit = 0
      res.max_limit = 10000
      res.min_value = 0
      res.max_value = 10000
      res.step = 1
      break
    case 'hard':
      res.min_limit = 0
      res.max_limit = 10000
      res.min_value = 0
      res.max_value = 10000
      res.step = 1
      break

    case 'tvctbc':
      res.min_limit = 0
      res.max_limit = 10000000000000
      res.min_value = 0
      res.max_value = 10000000000000
      res.step = 1
      break
    case 'tbc':
      res.min_limit = 0
      res.max_limit = 10000000000000
      res.min_value = 0
      res.max_value = 10000000000000
      res.step = 1
      break
    case 'yvc':
      res.min_limit = 0
      res.max_limit = 1000000000
      res.min_value = 0
      res.max_value = 1000000000
      res.step = 1
      break
    case 'gvc':
      res.min_limit = 0
      res.max_limit = 1000000
      res.min_value = 0
      res.max_value = 1000000
      res.step = 1
      break
    case 'lbc':
      res.min_limit = 0
      res.max_limit = 1000000
      res.min_value = 0
      res.max_value = 1000000
      res.step = 1
      break
    case 'bvc':
      res.min_limit = 0
      res.max_limit = 1000000
      res.min_value = 0
      res.max_value = 1000000
      res.step = 1
      break
    case 'tvc':
      res.min_limit = 0
      res.max_limit = 10000000
      res.min_value = 0
      res.max_value = 10000000
      res.step = 1
      break
    case 'plankton':
      res.min_limit = 0
      res.max_limit = 1000000000
      res.min_value = 0
      res.max_value = 1000000000
      res.step = 1
      break
    case 'bga':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'ga':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'gga':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'dino':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'dia':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'pro':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
    case 'eug':
      res.min_limit = 0
      res.max_limit = 100
      res.min_value = 0
      res.max_value = 100
      res.step = 1
      break
  }

  return res
}

wq.getWqLabel = (item_name) => {
  switch (item_name.toLowerCase()) {
    case 'ph':
      return 'pH'
    case 'ph_morning_to_morning':
      return `pH ${i18n.t('wq.morning')} ${i18n.t('to')} ${i18n.t('wq.morning')}`
    case 'ph_morning_to_evening':
      return `pH ${i18n.t('wq.morning')} ${i18n.t('to')} ${i18n.t('wq.evening')}`
    case 'ph_evening_to_evening':
      return `pH ${i18n.t('wq.evening')} ${i18n.t('to')} ${i18n.t('wq.evening')}`
    case 'oxygen_saturation':
    case 'oxygen':
      return i18n.t('wq.oxygen')
    case 'alkalinity':
      return item_name.split('_').map(e => {
        return e[0].toUpperCase() + e.substring(1)
      }).join(' ')
    case 'temperature':
    case 'turbidity':
    case 'salinity':
      return i18n.t('wq.' + item_name)
    case 'water_height':
      return i18n.t('wq.wh')
    case 'do':
    case 'tom':
    case 'po4':
    case 'no3':
    case 'no2':
    case 'nh4':
    case 'nh3':
    case 'tan':
    case 'co3':
    case 'hco3':
    case 'ca':
    case 'mg':
    case 'tbc':
    case 'yvc':
    case 'gvc':
    case 'lbc':
    case 'bvc':
    case 'tvc':
    case 'orp':
      return item_name.toUpperCase()
    case 'tvctbc':
      return 'TVC/TBC(%)'
    case 'hard':
      return 'Hardness'
    case 'plankton':
      return 'Plankton'
    case 'bga':
    case 'ga':
    case 'gga':
    case 'dino':
    case 'pro':
    case 'eug':
    case 'dia':
      return '%' + item_name.toUpperCase()
    default:
      return item_name
  }
}

export default wq
