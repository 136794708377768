<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content" v-if="this.form.all_general">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Set Parameters</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">

        <div class="columns is-tablet">
          <div class="column is-4-tablet">

            <div class="is-divider mt-0"></div>

            <b-field class="is-flex mb-0">
              <b-checkbox v-model="form.all_general.value" @input="selectAll('general')">{{ $t('select') }} {{ $t('all') }}</b-checkbox>
            </b-field>

            <div class="is-divider"></div>

            <p class="has-text-weight-bold mb-3">{{ $t('wq.general') }}</p>

            <b-field>
              <b-checkbox v-model="form.ph.value">pH</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.ph_morning_to_morning.value">pH {{ $t('wq.morning') }} {{ $t('to') }} {{ $t('wq.morning') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.ph_morning_to_evening.value">pH {{ $t('wq.morning') }} {{ $t('to') }} {{ $t('wq.evening') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.ph_evening_to_evening.value">pH {{ $t('wq.evening') }} {{ $t('to') }} {{ $t('wq.evening') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.do.value">DO</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.temperature.value">{{ $t('wq.temperature') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.turbidity.value">{{ $t('wq.turbidity') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.salinity.value">{{ $t('wq.salinity') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.water_height.value">{{ $t('wq.wh') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.oxygen_saturation.value">{{ $t('wq.oxygen') }}</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="form.orp.value">ORP</b-checkbox>
            </b-field>
          </div>

          <div class="column is-4-tablet">

            <div class="is-divider mt-0"></div>

            <b-field class="is-flex mb-0">
              <b-checkbox v-model="form.all_chemical.value" @input="selectAll('chemical')">{{ $t('select') }} {{ $t('all') }}</b-checkbox>
            </b-field>

            <div class="is-divider"></div>

            <p class="has-text-weight-bold mb-3">{{ $t('wq.chemical') }}</p>

            <b-field>
              <b-checkbox v-model="form.tom.value">TOM</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.alkalinity.value">Alkalinity</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.po4.value">PO4</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.no3.value">NO3</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.no2.value">NO2</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.nh4.value">NH4</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.nh3.value">NH3</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.tan.value">TAN</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.co3.value">CO3</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.hco3.value">HCO3</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.ca.value">Ca</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.mg.value">Mg</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.hard.value">Hardness</b-checkbox>
            </b-field>
          </div>

          <div class="column is-4-tablet">

            <div class="is-divider mt-0"></div>

            <b-field class="is-flex mb-0">
              <b-checkbox v-model="form.all_biological.value" @input="selectAll('biological')">{{ $t('select') }} {{ $t('all') }}</b-checkbox>
            </b-field>

            <div class="is-divider"></div>

            <p class="has-text-weight-bold mb-3">{{ $t('wq.biological') }}</p>

            <b-field>
              <b-checkbox v-model="form.tbc.value">TBC</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.tvc.value">TVC</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.yvc.value">YVC</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.gvc.value">GVC</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.lbc.value">LBC</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.bvc.value">BVC</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.tvctbc.value">TVC/TBC(%)</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.ga.value">%GA</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.gga.value">%GGA</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.dino.value">%DINO</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.dia.value">%DIA</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.pro.value">%PRO</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.eug.value">%EUG</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="form.plankton.value">Plankton</b-checkbox>
            </b-field>
          </div>
        </div>

        <b-button type="is-primary is-gradient" expanded class="has-text-weight-light" @click="setParameter">
          SET
        </b-button>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetParameters",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
    }
  },
  data: () => {
    return {
      form: {},
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.form = this._.cloneDeep(this.value)
      }
    },
    form: {
      deep: true,
      handler() {
        this.handleFormChange()
      }
    }
  },
  methods: {
    setParameter() {
      this.$emit('input', this.form)
      this.$emit('close')
    },
    selectAll(category) {
      let value = this.form['all_' + category].value

      for (let k in this.form) {
        let item = this.form[k]
        if (item.category === category) item.value = value
      }
    },
    handleFormChange() {
      let general = true
      let chemical = true
      let biological = true

      for (let k in this.form) {
        let item = this.form[k]

        if (!item.value && item.category) {
          switch (item.category) {
            case 'general':
              general = false
              break
            case 'chemical':
              chemical = false
              break
            case 'biological':
              biological = false
              break
          }
        }
      }

      this.form.all_general.value = general
      this.form.all_chemical.value = chemical
      this.form.all_biological.value = biological
    }
  },
  created() {
    this.form = this._.cloneDeep(this.value)
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>

